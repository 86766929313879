"use strict";

var _getCookie;
let colors;
colors = ['darkgreen', 'darkred', '#be6800', 'darkblue', 'dodgerblue', 'purple'];

/*
colors = [
    "#7CB5EC",
    "#434348",
    "#90ED7D",
    "#F7A35C",
    "#8085E9",
    "#F15C80",
    "#E4D354",
    "#2B908F",
    "#F45B5B",
    "#91E8E1",

    "#B0CC99",
    "#677E52",
    "#F6E8B1",
    "#89725B",
    "#046380",
    "#002F2F",
    "#B9121B",
    "#4C1B1B",
    "#5EB6DD",
    "#C79F4B",
    "#ABC8E2",
    "#FFF168",
    "#8FCF3C",
    "#C44C51",
    "#FF5B2B",
    "#52251C",
    "#B78178",
    "#FFB6B8",
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'brown'
];
*/
// let colors = ['green', 'red', 'orange', 'blue', 'purple', 'brown'];

let uuid = (_getCookie = getCookie('uuid')) !== null && _getCookie !== void 0 ? _getCookie : "";
let socket_url = location.protocol + '//' + location.host + (salle ? '/' + salle : "") + "?" + (user ? "user=" + user : "") + (uuid ? "&uuid=" + uuid : "");
console.log("socket_url", socket_url);
let socket = io.connect(socket_url, {
  //   forceNew: true, // Forcer une nouvelle connexion
  transports: ['websocket'],
  reconnection: true,
  // Autoriser la reconnexion
  reconnectionAttempts: 10,
  // Nombre de tentatives de reconnexion
  reconnectionDelay: 1000 // Délai entre chaque reconnexion (1 seconde)
});
function merge(obj1, obj2) {
  let ret = obj1;
  for (var i in obj2) {
    ret[i] = obj2[i];
  }
  return ret;
}
;
function getCookie(name) {
  let cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
    let [key, value] = cookie.split('=');
    if (key === name) {
      return decodeURIComponent(value);
    }
  }
  return null;
}
function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/';
}
let votesEnCours = {};
socket.on('voteStarted', function (voteEtat) {
  console.log('vote started', voteEtat);
});
socket.on('votePaused', function (voteEtat) {
  console.log('vote paused', voteEtat);
});
socket.on('voteStopped', function (voteEtat) {
  console.log('vote stopped', voteEtat);
});
socket.on('getVotesEnCours', function (votes) {
  console.log('getVotesEnCours', votes);
  votesEnCours = votes;
  //document.getElementById('container').innerHTML = votes;
});
socket.on('UUID', function (UUID) {
  console.log(UUID);
  setCookie('uuid', UUID, 1); // Stocke 'uuid' pour 1 jour
});
let Vote = {
  getVoteReponses: vote => {
    console.log('getVoteReponses');
    console.log(vote);
    let lettres_reponses = ['A', 'B', 'C', 'D', 'E', 'F'];
    if (vote.type_reponses == 'YN') return {
      Yes: 'Yes',
      No: 'No'
    };
    if (vote.type_reponses == 'YNA') return {
      Yes: 'Yes',
      No: 'No',
      Abstain: 'Abstain'
    };
    let ret = {};
    for (let i = 0; i < vote.nb_reponses; i++) {
      if (vote['question_texte_' + lettres_reponses[i]]) ret[lettres_reponses[i]] = vote['question_texte_' + lettres_reponses[i]];else {
        ret[lettres_reponses[i]] = vote.type_reponses == "A" ? lettres_reponses[i] : i + 1 + "";
      }
    }
    console.log(ret);
    return ret;
  },
  getVotesEnCours: () => {
    console.log('getVotesEnCours');
    socket.emit('getVotesEnCours');
  }
};